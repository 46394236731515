/*!
 * 
 *  Custom Scripts
 * 
 */

//===================================================================		
//  Push the footer to bottom of page
//===================================================================

/*jQuery(document).ready(function( $ ) {
   var docHeight = $(window).height();
   var footerHeight = $('#footer').outerHeight();
   var footerTop = $('#footer').position().top + footerHeight;

   if (footerTop < docHeight) {
    $('#footer').css('margin-top', 10+ (docHeight - footerTop) + 'px');
   }
  });*/

//===================================================================		
// END of Push the footer to bottom of page
//===================================================================	

	
//===================================================================		
//  Start Cookie Bar
//===================================================================
jQuery(document).ready(function( $ ) {
$.cookieBar({
message:'We use cookies to give you the best online experience. By using this website you agree with our cookie policy.',
fixed: true,
acceptOnScroll: 300,
bottom: true,
effect: 'slide',
zindex: '5001',
policyButton: false,
policyText: 'Read more',
//policyURL: ''
policyURL: ''

});
});
//===================================================================		
// END of Cookie Bar
//===================================================================


//===================================================================		
//  SCROLL To Top
//===================================================================

// SCROLL to TOP
jQuery(document).ready(function( $ ) {
	
	//Check to see if the window is top if not then display button
	$(window).scroll(function(){
		if ($(this).scrollTop() >= 1200) {		// If page is scrolled more than 50px
			$('.scrollToTop').fadeIn(800);		// Fade in the arrow
		} else {
			$('.scrollToTop').fadeOut(800);		// Else fade out the arrow
		}
	});
	
	//Click event to scroll to top
	$('.scrollToTop').click(function(){					// When arrow is clicked
		$('html, body').animate({scrollTop : 0},800); 	// Scroll to top of body
		return false;
	});
	
});

//===================================================================		
// END of Comment
//===================================================================


//===================================================================		
//  Reviews Slider
//===================================================================

jQuery(document).ready(function( $ ) {
 
  $("#owl-reviews").owlCarousel({
	 margin: 80,
 	stagePadding: 50,
	nav:false, // Show next/prev buttons.
	//navText: ['next','prev'], // next/prev button text
	navText: ['<i class="fa fa-chevron-left" aria-hidden="true"></i>','<i class="fa fa-chevron-right" aria-hidden="true"></i>'], // next/prev button text
	dots:true, // Show dots navigation.
	autoplaySpeed: 3000,
	navSpeed:9000,
	dotsSpeed:3000,
	//  singleItem:true,
	  
	    loop:true,
	autoplay:true,
 
      items : 1, 
      itemsDesktop : false,
      itemsDesktopSmall : false,
      itemsTablet: false,
      itemsMobile : false
 
  });
 
});
//===================================================================		
// END of  Owl Carousel
//===================================================================

//===================================================================		
//  Owl Tab - for Projects
//===================================================================

jQuery(document).ready(function( $ ) {
	
	
$('.owl-tab').owlCarousel({
	//fix for 1 image in loop
 loop: $("#owl-tab").find('.tab-item').size() > 1 ? true:false,
    margin:10,
	 loop:true,
	autoplay:true,
	autoplayHoverPause:true,
	autoplaySpeed: 1000,
	navSpeed:1000,
	dotsSpeed:1000,
 autoHeight: true,
	dots:false,
	//fix for hide nav for one Image
nav: $("#owl-tab").find(".tab-item").size() > 1 ? true:false,
	  singleItem:true,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:1
			        },
        1000:{
            items:1
        }
    },
	
	 onInitialized: setOwlStageHeight,
    onResized: setOwlStageHeight,
    onTranslated: setOwlStageHeight
 

});


function setOwlStageHeight(event) {
    var maxHeight = 0;
    $('.owl-tab .owl-item.active').each(function () { // LOOP THROUGH ACTIVE ITEMS
        var thisHeight = parseInt( $(this).height() );
        maxHeight=(maxHeight>=thisHeight?maxHeight:thisHeight);
    });
   // $('.owl-carousel').css('height', maxHeight );
    $('.owl-tab .owl-stage-outer').css('height', maxHeight ); // CORRECT DRAG-AREA SO BUTTONS ARE CLICKABLE
};

});
//===================================================================		
//  End Owl Tab - for Projects
//===================================================================


//===================================================================		
//  gallery - Owl Carousel http://www.owlcarousel.owlgraphic.com/docs/api-options.html
//===================================================================
jQuery(document).ready(function( $ ) {
  $('.owl-gallery').owlCarousel({
/*	  	 margin: 80,
 	stagePadding: 50,*/
    loop:true,
	autoplay:true,
	autoplayHoverPause:true,
	autoplaySpeed: 3000,
	navSpeed:1000,
	dotsSpeed:1000,
	 autoHeight: false,
	nav:true, // Show next/prev buttons.
	//navText: ['next','prev'], // next/prev button text
	navText: ['<i class="fa fa-chevron-left" aria-hidden="true"></i>','<i class="fa fa-chevron-right" aria-hidden="true"></i>'], // next/prev button text
	dots:false, // Show dots navigation.
	
    margin:10,
   // nav:true,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:2
        },
        1000:{
            items:4
        }
    },
	
		
/* onInitialized: setOwlStageHeight,
    onResized: setOwlStageHeight,
    onTranslated: setOwlStageHeight*/
	
});
});
//===================================================================		
// END of  Owl Carousel
//===================================================================


//===================================================================		
//  HOMEPAGE VIDEO Slider
//===================================================================

jQuery(document).ready(function( $ ) {
 
  $("#owl-videos").owlCarousel({
	 margin: 0,
 	stagePadding: 0,
	nav:true, // Show next/prev buttons.
	//navText: ['next','prev'], // next/prev button text
	navText: ['<i class="fa fa-chevron-left" aria-hidden="true"></i>','<i class="fa fa-chevron-right" aria-hidden="true"></i>'], // next/prev button text
	
	dots:false, // Show dots navigation.
	autoplayTimeout:10000,
autoplaySpeed: 2000,
	navSpeed:2000,
	dotsSpeed:2000,
	//  singleItem:true,
	  
	    loop:true,
	autoplay:true,
 
      items : 1, 
      itemsDesktop : false,
      itemsDesktopSmall : false,
      itemsTablet: false,
      itemsMobile : false
 
  });
 
});
//===================================================================		
// END of HOMEPAGE VIDEO Slider
//===================================================================




//===================================================================		
//  Featured Gallery - Owl Carousel http://www.owlcarousel.owlgraphic.com/docs/api-options.html
//===================================================================
jQuery(document).ready(function( $ ) {
  $('.owl-featured').owlCarousel({
	margin: 20,
 	stagePadding: 100,
    loop:true,
	autoplay:true,
	autoplayHoverPause:true,
	autoplaySpeed: 3000,
	navSpeed:1000,
	dotsSpeed:1000,
	 autoHeight: false,
	nav:true, // Show next/prev buttons.
	//navText: ['next','prev'], // next/prev button text
	navText: ['<i class="fa fa-chevron-left" aria-hidden="true"></i>','<i class="fa fa-chevron-right" aria-hidden="true"></i>'], // next/prev button text
	dots:false, // Show dots navigation.
    responsive:{
        0:{
            items:1
        },
        600:{
            items:2
        },
        1000:{
            items:3
        }
    },
	
		
/* onInitialized: setOwlStageHeight,
    onResized: setOwlStageHeight,
    onTranslated: setOwlStageHeight*/
	
});
});
//===================================================================		
// END of  Owl Carousel
//===================================================================


//===================================================================		
//  Before & AFTER Gallery - Owl Carousel http://www.owlcarousel.owlgraphic.com/docs/api-options.html
//===================================================================
jQuery(document).ready(function( $ ) {
  $('.before-after').owlCarousel({
	 margin: 40,
 	/*stagePadding: 100,*/
    loop:true,
	autoplay:true,
	autoplayHoverPause:true,
	autoplaySpeed: 3000,
	navSpeed:1000,
	dotsSpeed:1000,
	autoHeight: false,
	nav:true, // Show next/prev buttons.
	//navText: ['next','prev'], // next/prev button text
	navText: ['<i class="fa fa-chevron-left" aria-hidden="true"></i>','<i class="fa fa-chevron-right" aria-hidden="true"></i>'], // next/prev button text
	dots:false, // Show dots navigation.
    responsive:{
        0:{
            items:1
        },
        600:{
            items:2
        },
        1000:{
            items:2
        }
    },
	
		
/* onInitialized: setOwlStageHeight,
    onResized: setOwlStageHeight,
    onTranslated: setOwlStageHeight*/
	
});
});
//===================================================================		
// END of  Owl Carousel
//===================================================================



/*----------------------------------------------------------- 
/* Sticky & Shrinking Header
---------------------------------------------------------- */

  //caches a jQuery object containing the header element
/*    var header = jQuery(".site-header");
	
    jQuery(window).scroll(function() {
        var scroll = jQuery(window).scrollTop();

        if (scroll >= 50) {
     
            jQuery(".site-header").addClass("shrink");

        } else {
            jQuery(".site-header").removeClass("shrink");
     
		}
		
		});	*/


/*----------------------------------------------------------- 
 * End Sticky & Shrinking Header
---------------------------------------------------------- */


/*----------------------------------------------------------- 
/* ModuloBox LIGHTBOX
---------------------------------------------------------- */

// wait for document loaded
jQuery(document).ready(function(){

   // create instance of ModuloBox
    var mobx = new ModuloBox( {
	
	mediaSelector : '.mobx',
    scrollToZoom  : true,
    shareButtons  : ['facebook', 'googleplus', 'twitter', 'pinterest', 'linkedin'],
	controls : ['zoom', 'play', 'fullScreen', 'download', 'share', 'close'],
	prevNext : true,
	prevNextTouch : true
	
	});
    // initialize the instance	
    mobx.init(); 
    
});

/*----------------------------------------------------------- 
 * End ModuloBox LIGHTBOX
---------------------------------------------------------- */




/*----------------------------------------------------------- 
/* ModuloBox VIDEO
---------------------------------------------------------- */

/*// wait for document loaded
jQuery(document).ready(function($){

   // create instance of ModuloBox
    var mobx2 = new ModuloBox( {
		
	mediaSelector : '.mobxVideo',
	videoAutoPlay : true,
    scrollToZoom  : true,
    shareButtons  : ['facebook', 'googleplus', 'twitter', 'pinterest', 'linkedin'],
	controls : ['zoom', 'play', 'fullScreen', 'download', 'share', 'close'],
	prevNext : true,
	prevNextTouch : true,

	autoCaption : true,
	preload : 3
	
	});
    // initialize the instance	
    mobx2.init(); 
    
});*/

/*----------------------------------------------------------- 
 * End ModuloBox LIGHTBOX
---------------------------------------------------------- */












/*----------------------------------------------------------- 
 *  Form Options Show / Hide
---------------------------------------------------------- */
	
jQuery(document).ready(function() {
	
	jQuery("[name='qf_contactMethod']").change(function(){ 
    //alert($(this).val());
    if(jQuery(this).val() == "by Phone" )
    {
        jQuery('#callTime').slideDown();
    }
    else
    {
		// dide boxes
         jQuery('#callTime').slideUp();
		  jQuery('#timebottom').slideUp();
		  // reset values
		  jQuery("#qf_specifictime").val("");
		  jQuery("#qf_mytime").val(jQuery("#qf_mytime option:first").val());

    }
     });
	
	
  
	jQuery("[name='qf_mytime']").change(function(){ 
    //alert($(this).val());
    if(jQuery(this).val() == "othertime" )
    {
        jQuery('#timebottom').slideDown();
    }
    else
    {
         jQuery('#timebottom').slideUp();
    }
     });
	 
});



/*----------------------------------------------------------- 
 *  END Form Options Show / Hide
---------------------------------------------------------- */


/*----------------------------------------------------------- 
/* Animate On Scroll
---------------------------------------------------------- */
/*jQuery(document).ready(function($) {
	
 AOS.init({
 disable: 'mobile'
 });
});
*/
/*----------------------------------------------------------- 
 * End Animate On Scroll
---------------------------------------------------------- */


/*---------------------------------------------------------------------------------- 
/*   START :- Add Sticky to Header if Big Screen
--------------------------------------------------------------------------------- */

/*jQuery(document).ready(function($) {
	
if (window.innerWidth > 969) {
    $.UIkit.sticky('#header', {top: 0});
}

if (window.innerWidth < 969) {

  $("#header").removeAttr("data-uk-sticky");

}


});*/


/*---------------------------------------------------------------------------------- 
 * END :- Add Sticky to Header if Big Screen
--------------------------------------------------------------------------------- */
